






























































































































import Component, { mixins } from "vue-class-component";
import { MembershipTableItem } from "@/types/Memberships";
import { Header } from "@/types/Table";
import { LoadingMixin } from "@/mixins/Helpers";
import { httpsCallable } from "@firebase/functions";
import { MembershipResponse } from "@/store/actions/memberships";
import { Functions } from "@/firebase";
import {
  FetchLatestStripeMembershipsRequest,
  StripeMerchantInfo
} from "@sportango/backend";
import { WatchLoading } from "@/decorators/Loading";
import dayjs from "dayjs";
import RefreshIcon from "@/assets/icons/saxcons/refresh-circle-linear.svg";
import { ResponsiveMixin } from "@/mixins/Responsive";
import {
  SportangoTextArea,
  SportangoTextField
} from "@/components/Inputs/overrides";
import { StripeClient } from "@/mixins/Stripe";

@Component({
  name: "memberships",
  components: {
    SportangoTextArea,
    SportangoTextField,
    RefreshIcon
  }
})
export default class Memberships extends mixins(
  LoadingMixin,
  ResponsiveMixin,
  StripeClient
) {
  errorMessage: string | null = null;
  membershipsEnabled = false;
  membershipsRequired = false;
  joiningFeeEnabled = false;
  joiningFeePrice = 0;
  joiningFeeName = "";
  joiningFeeDescription = "";
  get headers(): Array<Header<MembershipTableItem>> {
    return [
      {
        text: "Name",
        value: "name",
        align: "start"
      },
      {
        text: "Description",
        value: "description",
        align: "start",
        width: 300
      },
      {
        text: "Price",
        value: "price",
        align: "center"
      },
      {
        text: "Created",
        value: "createdAt",
        align: "center"
      },
      {
        value: "lastUpdated",
        text: "Updated",
        align: "center"
      }
    ];
  }

  get items(): Array<MembershipTableItem> {
    return this.$store.getters.memberships.map((m) => {
      return {
        name: m.name,
        description: m.description || "No description added",
        createdAt: dayjs(m.createdAt).format("MM/DD/YYYY"),
        lastUpdated: dayjs(m.updatedAt).format("MM/DD/YYYY"),
        price: `$${m.price.amount / 100}`
      };
    });
  }

  async loadData() {
    await this.$store.dispatch("getAllMemberships");
    if (
      typeof this.$store.getters.merchantInfo?.feeConfig?.enableMemberships ===
      "undefined"
    ) {
      this.membershipsEnabled = this.$store.getters.featureFlags.memberships;
    } else {
      this.membershipsEnabled =
        this.$store.getters.merchantInfo?.feeConfig?.enableMemberships;
    }
    if (this.$store.getters.merchantInfo?.feeConfig) {
      this.joiningFeeName =
        this.$store.getters.merchantInfo.feeConfig.joiningFeeConfig
          ?.nameOfCharge || "";
      this.joiningFeeDescription =
        this.$store.getters.merchantInfo.feeConfig.joiningFeeConfig
          ?.descriptionOfCharge || "";
      this.joiningFeePrice =
        this.$store.getters.merchantInfo.feeConfig.joiningFeeConfig
          ?.amountToCharge || 0;
      this.joiningFeeEnabled =
        this.$store.getters.merchantInfo.feeConfig.enableJoiningFee || false;
      this.membershipsRequired =
        this.$store.getters.merchantInfo.feeConfig.isMembershipRequired ||
        false;
    }
  }

  async stripeClientLoaded() {
    await this.loadData();
  }

  async mounted() {
    await this.loadData();
  }

  @WatchLoading()
  async save() {
    const payload: Partial<StripeMerchantInfo> = {
      membershipsEnabled: this.membershipsEnabled,
      feeConfig: {
        enableMemberships: this.membershipsEnabled,
        enableJoiningFee: this.joiningFeeEnabled,
        isMembershipRequired: this.membershipsRequired,
        joiningFeeConfig: {
          amountToCharge: Number(this.joiningFeePrice),
          descriptionOfCharge: this.joiningFeeDescription,
          nameOfCharge: this.joiningFeeName
        }
      }
    };
    await this.$store.dispatch("updateMerchantInfo", payload);
  }

  @WatchLoading()
  async invokeFetch() {
    if (this.$store.getters.merchantInfo?.merchantId) {
      const { data } = await httpsCallable<
        FetchLatestStripeMembershipsRequest,
        MembershipResponse
      >(
        Functions,
        "fetchLatestMemberships"
      )({
        accountId: this.$store.getters.merchantInfo.merchantId
      });
      if (data.isSuccess) {
        await this.$store.dispatch("getAllMemberships");
      } else if (data.message) {
        this.errorMessage = data.message;
      }
    }
  }
}
